let TreeList = [
  /* 
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
  */
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "獐芽菜苦苷",

    cbh: "CHEMBL500001", //编号
    cmc: "Swertiamarin", //名称（英文）
    cjgt: "/image/獐芽菜苦苷.png", //结构图
    cfzs: "C21H30O13", //分子式
    cfzl: "490.455", //分子量
    ctym: "獐芽菜苦苷", //通用名、商品名（中文）
    ccas: "266678-59-5", //CAS号
    csmiles:
      "C=C[C@H]1[C@H](O[C@@H]2O[C@H](CO)[C@@H](O)[C@H](O)[C@H]2O)OC=C2C(=O)OCC[C@]21O", //SMILES
    cinchi:
      "InChI=1S/C16H22O10/c1-2-7-14(24-6-8-13(21)23-4-3-16(7,8)22)26-15-12(20)11(19)10(18)9(5-17)25-15/h2,6-7,9-12,14-15,17-20,22H,1,3-5H2/t7-,9+,10+,11-,12+,14-,15-,16+/m0/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "獐芽菜苦苷", //效应成分
    cylzb: "肺指数改善率%;IL-6改善率%;IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（22%）;IL-6改善率%（5.54%）;IFN-γ改善率%（12.60%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "新绿原酸",

    cbh: "CHEMBL500002", //编号
    cmc: "Neochlorogenic acid", //名称（英文）
    cjgt: "/image/新绿原酸.png", //结构图
    cfzs: "C16H18O9", //分子式
    cfzl: "354.309", //分子量
    ctym: "新绿原酸", //通用名、商品名（中文）
    ccas: "906-33-2", //CAS号
    csmiles:
      "O=C(/C=C/c1ccc(O)c(O)c1)O[C@@H]1C[C@@](O)(C(=O)O)C[C@@H](O)[C@@H]1O", //SMILES
    cinchi:
      "InChI=1S/C16H18O9/c17-9-3-1-8(5-10(9)18)2-4-13(20)25-12-7-16(24,15(22)23)6-11(19)14(12)21/h1-5,11-12,14,17-19,21,24H,6-7H2,(H,22,23)/b4-2+/t11-,12-,14+,16-/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "新绿原酸", //效应成分
    cylzb: "肺指数改善率%;IL-6改善率%;IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（2.93%）;IL-6改善率%（12.15%）;IFN-γ改善率%（6.89%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "咖啡酸",

    cbh: "CHEMBL500003", //编号
    cmc: "trans-Caffeic acid", //名称（英文）
    cjgt: "/image/咖啡酸.png", //结构图
    cfzs: "C9H8O4", //分子式
    cfzl: "180.157", //分子量
    ctym: "咖啡酸", //通用名、商品名（中文）
    ccas: "501-16-6", //CAS号
    csmiles: "O=C(O)/C=C/c1ccc(O)c(O)c1", //SMILES
    cinchi:
      "InChI=1S/C9H8O4/c10-7-3-1-6(5-8(7)11)2-4-9(12)13/h1-5,10-11H,(H,12,13)/b4-2+", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "咖啡酸", //效应成分
    cylzb: "肺指数改善率%;IL-6改善率%;IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（11.25%）;IL-6改善率%（9.59%）; IFN-γ改善率%（14.57%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "异绿原酸C",

    cbh: "CHEMBL500004", //编号
    cmc: "Isochlorogenic acid C", //名称（英文）
    cjgt: "/image/异绿原酸C.png", //结构图
    cfzs: "C25H24O12", //分子式
    cfzl: "516.451", //分子量
    ctym: "异绿原酸C", //通用名、商品名（中文）
    ccas: "57378-72-0", //CAS号
    csmiles:
      "O=C(/C=C/c1ccc(O)c(O)c1)O[C@@H]1C[C@](O)(C(=O)O)C[C@@H](OC(=O)/C=C/c2ccc(O)c(O)c2)[C@H]1O", //SMILES
    cinchi:
      "InChI=1S/C25H24O12/c26-15-5-1-13(9-17(15)28)3-7-21(30)36-19-11-25(35,24(33)34)12-20(23(19)32)37-22(31)8-4-14-2-6-16(27)18(29)10-14/h1-10,19-20,23,26-29,32,35H,11-12H2,(H,33,34)/b7-3+,8-4+/t19-,20-,23-,25+/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "异绿原酸C", //效应成分
    cylzb: "肺指数改善率%;IL-6改善率%;IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（10.51%）;IL-6改善率%（2.99%）;IFN-γ改善率%（4.92%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "木犀草苷",

    cbh: "CHEMBL500005", //编号
    cmc: "Cynaroside", //名称（英文）
    cjgt: "/image/木犀草苷.png", //结构图
    cfzs: "C21H20O11", //分子式
    cfzl: "448.377", //分子量
    ctym: "木犀草苷", //通用名、商品名（中文）
    ccas: "5373-11-5", //CAS号
    csmiles:
      "O=c1cc(-c2ccc(O)c(O)c2)oc2cc(O[C@@H]3O[C@H](CO)[C@@H](O)[C@H](O)[C@H]3O)cc(O)c12", //SMILES
    cinchi:
      "InChI=1S/C21H20O11/c22-7-16-18(27)19(28)20(29)21(32-16)30-9-4-12(25)17-13(26)6-14(31-15(17)5-9)8-1-2-10(23)11(24)3-8/h1-6,16,18-25,27-29H,7H2/t16-,18-,19+,20-,21-/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "木犀草苷", //效应成分
    cylzb: "肺指数改善率%; IL-6改善率%; IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（1.22%）;IL-6改善率%（18.34%）;IFN-γ改善率%（11.22%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "木犀草素",

    cbh: "CHEMBL500006", //编号
    cmc: "Luteolin", //名称（英文）
    cjgt: "/image/木犀草素.png", //结构图
    cfzs: "C15H10O6", //分子式
    cfzl: "286.236", //分子量
    ctym: "木犀草素", //通用名、商品名（中文）
    ccas: "491-70-3", //CAS号
    csmiles: "O=c1cc(-c2ccc(O)c(O)c2)oc2cc(O)cc(O)c12", //SMILES
    cinchi:
      "InChI=1S/C15H10O6/c16-8-4-11(19)15-12(20)6-13(21-14(15)5-8)7-1-2-9(17)10(18)3-7/h1-6,16-19H", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "木犀草素", //效应成分
    cylzb: "肺指数改善率%; IL-6改善率%; IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（19.07%） ;IL-6改善率%（8.96%）; IFN-γ改善率%（18.31%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "断氧化马钱子苷",

    cbh: "CHEMBL500007", //编号
    cmc: "Secoxyloganin", //名称（英文）
    cjgt: "/image/断氧化马钱子苷.png", //结构图
    cfzs: "C17H24O11", //分子式
    cfzl: "404.366", //分子量
    ctym: "断氧化马钱子苷", //通用名、商品名（中文）
    ccas: "58822-47-2", //CAS号
    csmiles:
      "C=C[C@H]1C(O[C@@H]2O[C@H](CO)[C@@H](O)[C@H](O)[C@H]2O)OC=C(C(=O)OC)[C@H]1CC(=O)O", //SMILES
    cinchi:
      "InChI=1S/C17H24O11/c1-3-7-8(4-11(19)20)9(15(24)25-2)6-26-16(7)28-17-14(23)13(22)12(21)10(5-18)27-17/h3,6-8,10,12-14,16-18,21-23H,1,4-5H2,2H3,(H,19,20)/t7-,8+,10-,12-,13+,14-,16?,17+/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "断氧化马钱子苷", //效应成分
    cylzb: "肺指数改善率%; IL-6改善率%; IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（2.20%）;IL-6改善率%（7.68%）;IFN-γ改善率%（5.12%）", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "绿原酸",

    cbh: "CHEMBL500008", //编号
    cmc: "Chlorogenic acid", //名称（英文）
    cjgt: "/image/绿原酸2.png", //结构图
    cfzs: "C16H18O9", //分子式
    cfzl: "354.309", //分子量
    ctym: "绿原酸", //通用名、商品名（中文）
    ccas: "327-97-9", //CAS号
    csmiles:
      "O=C(/C=C/c1ccc(O)c(O)c1)O[C@@H]1C[C@](O)(C(=O)O)C[C@@H](O)[C@H]1O", //SMILES
    cinchi:
      "InChI=1S/C16H18O9/c17-9-3-1-8(5-10(9)18)2-4-13(20)25-12-7-16(24,15(22)23)6-11(19)14(12)21/h1-5,11-12,14,17-19,21,24H,6-7H2,(H,22,23)/b4-2+/t11-,12-,14-,16+/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "绿原酸", //效应成分
    cylzb:
      "肺指数改善率%（金银花）; IL-6改善率%（金银花）; IFN-γ改善率%（金银花）;抑制金黄色葡萄球菌生长率%（金银花、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒;抑菌作用", //药理作用
    cgxd: "肺指数改善率%（金银花）【8.31%】; IL-6改善率%（金银花）【5.33%】; IFN-γ改善率%（金银花）【7.09%】; 抑制金黄色葡萄球菌生长率%（金银花）【8.12%】; 抑菌作用%（银黄颗粒）【12.45%】", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "异绿原酸B",

    cbh: "CHEMBL500009", //编号
    cmc: "Isochlorogenic acid B", //名称（英文）
    cjgt: "/image/异绿原酸B.png", //结构图
    cfzs: "C25H24O12", //分子式
    cfzl: "516.451", //分子量
    ctym: "异绿原酸B", //通用名、商品名（中文）
    ccas: "14534-61-3", //CAS号
    csmiles:
      "O=C(/C=C/c1ccc(O)c(O)c1)O[C@@H]1[C@H](O)C[C@@](O)(C(=O)O)C[C@H]1OC(=O)/C=C/c1ccc(O)c(O)c1", //SMILES
    cinchi:
      "InChI=1S/C25H24O12/c26-15-5-1-13(9-17(15)28)3-7-21(31)36-20-12-25(35,24(33)34)11-19(30)23(20)37-22(32)8-4-14-2-6-16(27)18(29)10-14/h1-10,19-20,23,26-30,35H,11-12H2,(H,33,34)/b7-3+,8-4+/t19-,20-,23-,25+/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "异绿原酸B", //效应成分
    cylzb:
      "肺指数改善率%（金银花）; IL-6改善率%（金银花）; IFN-γ改善率%（金银花）; 抑制金黄色葡萄球菌生长率%（金银花、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒；抑菌作用", //药理作用
    cgxd: "肺指数改善率%（金银花）【8.31%】; IL-6改善率%（金银花）【18.12%】;IFN-γ改善率%（金银花）【12.40%】; 抑制金黄色葡萄球菌生长率%（金银花）【9.2%】; 抑制金黄色葡萄球菌生长率%（银黄颗粒）【11.2%】", //贡献度
  },
  {
    firstTitle: "金银花",
    secondTitle: "抗RSV病毒",
    label: "1，5-二咖啡酰奎宁酸",

    cbh: "CHEMBL500010", //编号
    cmc: "1,5-Dicaffeoylquinic acid", //名称（英文）
    cjgt: "/image/1，5-二咖啡酰奎宁酸.png", //结构图
    cfzs: "C25H24O12", //分子式
    cfzl: "516.451", //分子量
    ctym: "1，5-二咖啡酰奎宁酸", //通用名、商品名（中文）
    ccas: "30964-13-7", //CAS号
    csmiles:
      "O=C(/C=C/c1ccc(O)c(O)c1)O[C@@H]1C[C@](OC(=O)/C=C/c2ccc(O)c(O)c2)(C(=O)O)C[C@@H](O)[C@H]1O", //SMILES
    cinchi:
      "InChI=1S/C25H24O12/c26-15-5-1-13(9-17(15)28)3-7-21(31)36-20-12-25(24(34)35,11-19(30)23(20)33)37-22(32)8-4-14-2-6-16(27)18(29)10-14/h1-10,19-20,23,26-30,33H,11-12H2,(H,34,35)/b7-3+,8-4+/t19-,20-,23-,25+/m1/s1", //InChI
    czyc: "金银花", //中药材
    cldm: "Lonicera japonica Thunb.", //拉丁名
    czwzs: "科信息：Caprifoliaceae 忍冬科；属信息：Lonicera 忍冬属", //植物种属
    czwtp: "/image/獐芽菜苦苷zw.png", //植物图片
    czcy: "银黄颗粒、金银花口服液、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "1，5-二咖啡酰奎宁酸", //效应成分
    cylzb: "肺指数改善率%; IL-6改善率% ;IFN-γ改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "肺指数改善率%（14.18%） ;IL-6改善率%（11.30%）;IFN-γ改善率%（6.89%）", //贡献度
  },
  /* 
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
  */
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "黄芩苷",

    cbh: "CHEMBL500017", //编号
    cmc: "Baicalin", //名称（英文）
    cjgt: "/image/黄芩苷.png", //结构图
    cfzs: "C21H18O11", //分子式
    cfzl: "446.361", //分子量
    ctym: "黄芩苷", //通用名、商品名（中文）
    ccas: "21967-41-9", //CAS号
    csmiles:
      "O=C(O)[C@H]1O[C@@H](Oc2cc3oc(-c4ccccc4)cc(=O)c3c(O)c2O)[C@H](O)[C@@H](O)[C@@H]1O", //SMILES
    cinchi:
      "InChI=1S/C21H18O11/c22-9-6-10(8-4-2-1-3-5-8)30-11-7-12(14(23)15(24)13(9)11)31-21-18(27)16(25)17(26)19(32-21)20(28)29/h1-7,16-19,21,23-27H,(H,28,29)/t16-,17-,18+,19-,21+/m0/s1", //InChI
    czyc: "", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "黄芩苷", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（11.3%）", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "汉黄芩苷",

    cbh: "CHEMBL500018", //编号
    cmc: "Wogonoside", //名称（英文）
    cjgt: "/image/汉黄芩苷.png", //结构图
    cfzs: "C22H20O11", //分子式
    cfzl: "460.388", //分子量
    ctym: "汉黄芩苷", //通用名、商品名（中文）
    ccas: "51059-44-0", //CAS号
    csmiles:
      "COc1c(O[C@H]2O[C@@H](C(=O)O)[C@H](O)[C@@H](O)[C@@H]2O)cc(O)c2c(=O)cc(-c3ccccc3)oc12", //SMILES
    cinchi:
      "InChI=1S/C22H20O11/c1-30-18-13(32-22-17(27)15(25)16(26)20(33-22)21(28)29)8-11(24)14-10(23)7-12(31-19(14)18)9-5-3-2-4-6-9/h2-8,15-17,20,22,24-27H,1H3,(H,28,29)/t15-,16-,17+,20-,22+/m1/s1", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "汉黄芩苷", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（10.5%）", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "黄芩素",

    cbh: "CHEMBL500019", //编号
    cmc: "Baicalin", //名称（英文）
    cjgt: "/image/黄芩素.png", //结构图
    cfzs: "C21H18O11", //分子式
    cfzl: "446.361", //分子量
    ctym: "黄芩素", //通用名、商品名（中文）
    ccas: "21967-41-9", //CAS号
    csmiles:
      "O=C(O)[C@H]1O[C@@H](Oc2cc3oc(-c4ccccc4)cc(=O)c3c(O)c2O)[C@H](O)[C@@H](O)[C@@H]1O", //SMILES
    cinchi:
      "InChI=1S/C21H18O11/c22-9-6-10(8-4-2-1-3-5-8)30-11-7-12(14(23)15(24)13(9)11)31-21-18(27)16(25)17(26)19(32-21)20(28)29/h1-7,16-19,21,23-27H,(H,28,29)/t16-,17-,18+,19-,21+/m0/s1", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "黄芩素", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（8.7%）", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "汉黄芩素",

    cbh: "CHEMBL500020", //编号
    cmc: "Wogonin", //名称（英文）
    cjgt: "/image/汉黄芩素.png", //结构图
    cfzs: "C16H12O5", //分子式
    cfzl: "284.263", //分子量
    ctym: "汉黄芩素", //通用名、商品名（中文）
    ccas: "632-85-9", //CAS号
    csmiles: "COc1c(O)cc(O)c2c(=O)cc(-c3ccccc3)oc12", //SMILES
    cinchi:
      "InChI=1S/C16H12O5/c1-20-15-12(19)7-10(17)14-11(18)8-13(21-16(14)15)9-5-3-2-4-6-9/h2-8,17,19H,1H3", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "汉黄芩素", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（9.5%）", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "白杨素",

    cbh: "CHEMBL500021", //编号
    cmc: "Chrysin", //名称（英文）
    cjgt: "/image/白杨素.png", //结构图
    cfzs: "C15H10O4", //分子式
    cfzl: "254.238", //分子量
    ctym: "白杨素", //通用名、商品名（中文）
    ccas: "480-40-0", //CAS号
    csmiles: "O=c1cc(-c2ccccc2)oc2cc(O)cc(O)c12", //SMILES
    cinchi:
      "InChI=1S/C15H10O4/c16-10-6-11(17)15-12(18)8-13(19-14(15)7-10)9-4-2-1-3-5-9/h1-8,16-17H", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "白杨素", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（5.2%）", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抗RSV病毒",
    label: "千层纸素",

    cbh: "CHEMBL500022", //编号
    cmc: "oroxylin A", //名称（英文）
    cjgt: "/image/千层纸素.png", //结构图
    cfzs: "C16H12O5", //分子式
    cfzl: "284.263", //分子量
    ctym: "千层纸素", //通用名、商品名（中文）
    ccas: "480-11-5", //CAS号
    csmiles: "COc1c(O)cc2oc(-c3ccccc3)cc(=O)c2c1O", //SMILES
    cinchi:
      "InChI=1S/C16H12O5/c1-20-16-11(18)8-13-14(15(16)19)10(17)7-12(21-13)9-5-3-2-4-6-9/h2-8,18-19H,1H3", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "千层纸素", //效应成分
    cylzb: "炎症因子IL-6改善率%", //药理指标
    cylzy: "抗RSV病毒", //药理作用
    cgxd: "炎症因子IL-6改善率%（14.8%）", //贡献度
  },
  /* 
    firstTitle: "黄芩",
    secondTitle: "抑菌作用",
  */
  {
    firstTitle: "黄芩",
    secondTitle: "抑菌作用",
    label: "5,7,8-三羟基黄酮(去甲汉黄芩素)",

    cbh: "CHEMBL500042", //编号
    cmc: "norwogonin", //名称（英文）
    cjgt: "/image/5,7,8-三羟基黄酮(去甲汉黄芩素).png", //结构图
    cfzs: "C15H10O5", //分子式
    cfzl: "270.237", //分子量
    ctym: "5,7,8-三羟基黄酮", //通用名、商品名（中文）
    ccas: "929064", //CAS号
    csmiles: "O=c1cc(-c2ccccc2)oc2c(O)c(O)cc(O)c12", //SMILES
    cinchi:
      "InChI=1S/C15H10O5/c16-9-6-11(18)14(19)15-13(9)10(17)7-12(20-15)8-4-2-1-3-5-8/h1-7,16,18-19H", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "5,7,8-三羟基黄酮", //效应成分
    cylzb:
      "炎症因子IL-6改善率%（黄芩）; 抑制金黄色葡萄球菌生长率%（黄芩、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒；抑菌作用", //药理作用
    cgxd: "炎症因子IL-6改善率%（黄芩）【10%】；抑制金黄色葡萄球菌生长率%（黄芩）【5.92%】； 抑菌作用%（银黄颗粒）【11.00%】", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抑菌作用",
    label: "去甲汉黄芩素-7-0葡萄糖酸苷",

    cbh: "CHEMBL500043", //编号
    cmc: "Glychionide A", //名称（英文）
    cjgt: "/image/去甲汉黄芩素-7-0葡萄糖酸苷.png", //结构图
    cfzs: "C21H18O11", //分子式
    cfzl: "446.361", //分子量
    ctym: "去甲汉黄芩素-7-O-葡萄糖醛酸苷", //通用名、商品名（中文）
    ccas: "119152-50-0", //CAS号
    csmiles:
      "O=C(O)[C@H]1O[C@@H](Oc2c(O)cc(O)c3c(=O)cc(-c4ccccc4)oc23)[C@H](O)[C@@H](O)[C@@H]1O", //SMILES
    cinchi:
      "InChI=1S/C21H18O11/c22-9-6-11(24)17(31-21-16(27)14(25)15(26)19(32-21)20(28)29)18-13(9)10(23)7-12(30-18)8-4-2-1-3-5-8/h1-7,14-16,19,21-22,24-27H,(H,28,29)/t14-,15-,16+,19-,21+/m0/s1", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf:"去甲汉黄芩素-7-O-葡萄糖醛酸苷", //效应成分
    cylzb: "炎症因子IL-6改善率%（黄芩）; 抑制金黄色葡萄球菌生长率%（黄芩、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒；抑菌作用", //药理作用
    cgxd: "炎症因子IL-6改善率%（黄芩）【10%】；抑制金黄色葡萄球菌生长率%（黄芩）【9.88%】；抑菌作用%（银黄颗粒）【10.15%】", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抑菌作用",
    label: "白杨素-7-0-葡萄糖醛酸苷",

    cbh: "CHEMBL500045", //编号
    cmc: "Chrysin-7-O-β-D-glucuronide", //名称（英文）
    cjgt: "/image/白杨素-7-0-葡萄糖醛酸苷.png", //结构图
    cfzs: "C21H18O10", //分子式
    cfzl: "430.36", //分子量
    ctym: "白杨素-7-0-葡萄糖醛酸苷", //通用名、商品名（中文）
    ccas: "35775-49-6", //CAS号
    csmiles:
      "CC(=O)Oc1cc(OC(C)=O)c2c(c1)O[C@@H](c1ccc(O)c(O)c1)[C@H](OC(C)=O)C2=O", //SMILES
    cinchi:
      "InChI=1S/C21H18O10/c1-9(22)28-13-7-16(29-10(2)23)18-17(8-13)31-20(21(19(18)27)30-11(3)24)12-4-5-14(25)15(26)6-12/h4-8,20-21,25-26H,1-3H3/t20-,21+/m0/s1", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "白杨素-7-0-葡萄糖醛酸苷", //效应成分
    cylzb:
      "炎症因子IL-6改善率%（黄芩）; 抑制金黄色葡萄球菌生长率%（黄芩、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒；抑菌作用", //药理作用
    cgxd: "炎症因子IL-6改善率%（黄芩）【7.4%】；抑制金黄色葡萄球菌生长率%（黄芩）【10.12%】；抑菌作用%（银黄颗粒）【12.05%】", //贡献度
  },
  {
    firstTitle: "黄芩",
    secondTitle: "抑菌作用",
    label: "木犀草苷",

    cbh: "CHEMBL500048", //编号
    cmc: "Cynaroside", //名称（英文）
    cjgt: "/image/木犀草苷2.png", //结构图
    cfzs: "C21H20O11", //分子式
    cfzl: "448.377", //分子量
    ctym: "木犀草苷", //通用名、商品名（中文）
    ccas: "5373-11-5", //CAS号
    csmiles:
      "O=c1cc(-c2ccc(O)c(O)c2)oc2cc(O[C@@H]3O[C@H](CO)[C@@H](O)[C@H](O)[C@H]3O)cc(O)c12", //SMILES
    cinchi:
      "InChI=1S/C21H20O11/c22-7-16-18(27)19(28)20(29)21(32-16)30-9-4-12(25)17-13(26)6-14(31-15(17)5-9)8-1-2-10(23)11(24)3-8/h1-6,16,18-25,27-29H,7H2/t16-,18-,19+,20-,21-/m1/s1", //InChI
    czyc: "黄芩", //中药材
    cldm: "Scutellaria baicalensis Georgi", //拉丁名
    czwzs: "科信息：Labiatae；属信息：Scutellaria 黄芩属", //植物种属
    czwtp: "/image/黄芩苷zw.png", //植物图片
    czcy: "银黄颗粒、双黄连口服液", //中成药
    cff: "", //复方
    cxycf: "木犀草苷", //效应成分
    cylzb:
      "炎症因子IL-6改善率%（黄芩）; 抑制金黄色葡萄球菌生长率%（黄芩、银黄颗粒）", //药理指标
    cylzy: "抗RSV病毒；抑菌作用", //药理作用
    cgxd: "炎症因子IL-6改善率%（黄芩）【12.6%】；抑制金黄色葡萄球菌生长率%（黄芩）【5.12%】; 抑菌作用%（银黄颗粒）【16.15%】", //贡献度
  },
  /* 
    firstTitle: "银黄颗粒",
    secondTitle: "抑菌作用",
  */
  /* 
    firstTitle: "西洋参",
    secondTitle: "增强免疫",
  */
  {
    firstTitle: "西洋参",
    secondTitle: "增强免疫",
    label: "Ginsenoside Rc",

    cbh: "CHEMBL500063", //编号
    cmc: "ginsenoside Rc", //名称（英文）
    cjgt: "/image/Ginsenoside Rc.png", //结构图
    cfzs: "C53H90O22", //分子式
    cfzl: "1079.269", //分子量
    ctym: "人参皂甙 Rc", //通用名、商品名（中文）
    ccas: "11021-14-0", //CAS号
    csmiles:
      "CC(C)=CCC[C@](C)(O[C@@H]1O[C@H](CO[C@@H]2O[C@@H](CO)[C@H](O)[C@H]2O)[C@@H](O)[C@H](O)[C@H]1O)[C@H]1CC[C@]2(C)[C@@H]1[C@H](O)C[C@@H]1[C@@]3(C)CC[C@H](O[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O)C(C)(C)[C@@H]3CC[C@]12C", //SMILES
    cinchi:
      "InChI=1S/C53H90O22/c1-23(2)10-9-14-53(8,75-47-43(67)39(63)37(61)29(72-47)22-68-45-41(65)36(60)28(21-56)69-45)24-11-16-52(7)33(24)25(57)18-31-50(5)15-13-32(49(3,4)30(50)12-17-51(31,52)6)73-48-44(40(64)35(59)27(20-55)71-48)74-46-42(66)38(62)34(58)26(19-54)70-46/h10,24-48,54-67H,9,11-22H2,1-8H3/t24-,25+,26+,27+,28-,29+,30-,31+,32-,33-,34+,35+,36-,37+,38-,39-,40-,41+,42+,43+,44+,45+,46-,47-,48-,50-,51+,52+,53-/m0/s1", //InChI
    czyc: "西洋参", //中药材
    cldm: "Panax quinquefolius L.", //拉丁名
    czwzs: "五加科人参属", //植物种属
    czwtp: "/image/Ginsenoside Rczw.png", //植物图片
    czcy: "", //中成药
    cff: "", //复方
    cxycf: "人参皂甙 Rc", //效应成分
    cylzb: "中性粒细胞增长率", //药理指标
    cylzy: "增强免疫", //药理作用
    cgxd: "中性粒细胞增长率（10.2%）", //贡献度
  },
  {
    firstTitle: "西洋参",
    secondTitle: "增强免疫",
    label: "Malonyl-Rb1异构体",

    cbh: "CHEMBL500064", //编号
    cmc: "Malonyl-ginsenoside Rb1 ", //名称（英文）
    cjgt: "/image/Malonyl-Rb1异构体.png", //结构图
    cfzs: "C57H94O26", //分子式
    cfzl: "1195.3", //分子量
    ctym: "丙二酰基人参皂苷Rb1", //通用名、商品名（中文）
    ccas: "41753-43-9", //CAS号
    csmiles:
      "CC(=CCCC(C)(C1CCC2(C1C(CC3C2(CCC4C3(CCC(C4(C)C)OC5C(C(C(C(O5)CO)O)O)OC6C(C(C(C(O6)COC(=O)CC(=O)O)O)O)O)C)C)O)C)OC7C(C(C(C(O7)COC8C(C(C(C(O8)CO)O)O)O)O)O)O)C", //SMILES
    cinchi:
      "InChI=1S/C57H94O26/c1-24(2)10-9-14-57(8,83-51-47(74)43(70)40(67)30(80-51)23-76-49-45(72)41(68)37(64)27(20-58)77-49)25-11-16-56(7)36(25)26(60)18-32-54(5)15-13-33(53(3,4)31(54)12-17-55(32,56)6)81-52-48(44(71)38(65)28(21-59)78-52)82-50-46(73)42(69)39(66)29(79-50)22-75-35(63)19-34(61)62/h10,25-33,36-52,58-60,64-74H,9,11-23H2,1-8H3,(H,61,62)/t25?,26-,27-,28-,29-,30-,31?,32?,33+,36+,37-,38-,39-,40-,41+,42+,43+,44+,45-,46-,47-,48-,49-,50+,51+,52+,54+,55-,56-,57+/m1/s1", //InChI
    czyc: "西洋参", //中药材
    cldm: "Panax quinquefolius L.", //拉丁名
    czwzs: "五加科人参属", //植物种属
    czwtp: "/image/Ginsenoside Rczw.png", //植物图片
    czcy: "", //中成药
    cff: "", //复方
    cxycf: "丙二酰基人参皂苷Rb1", //效应成分
    cylzb: "中性粒细胞增长率", //药理指标
    cylzy: "增强免疫", //药理作用
    cgxd: "中性粒细胞增长率（5.12%）", //贡献度
  },
  {
    firstTitle: "西洋参",
    secondTitle: "增强免疫",
    label: "Ginsenoside Rb1",

    cbh: "CHEMBL500066", //编号
    cmc: "Ginsenoside Rb1", //名称（英文）
    cjgt: "/image/Ginsenoside Rb1.png", //结构图
    cfzs: "C54H92O23", //分子式
    cfzl: "1109.295", //分子量
    ctym: "人参皂苷 Rb1", //通用名、商品名（中文）
    ccas: "41753-43-9", //CAS号
    csmiles:
      "CC(C)=CCC[C@](C)(O[C@@H]1O[C@H](CO[C@@H]2O[C@H](CO)[C@@H](O)[C@H](O)[C@H]2O)[C@@H](O)[C@H](O)[C@H]1O)[C@H]1CC[C@]2(C)[C@@H]1[C@H](O)C[C@@H]1[C@@]3(C)CC[C@H](O[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O)C(C)(C)[C@@H]3CC[C@]12C", //SMILES
    cinchi:
      "InChI=1S/C54H92O23/c1-23(2)10-9-14-54(8,77-48-44(69)40(65)37(62)29(74-48)22-70-46-42(67)38(63)34(59)26(19-55)71-46)24-11-16-53(7)33(24)25(58)18-31-51(5)15-13-32(50(3,4)30(51)12-17-52(31,53)6)75-49-45(41(66)36(61)28(21-57)73-49)76-47-43(68)39(64)35(60)27(20-56)72-47/h10,24-49,55-69H,9,11-22H2,1-8H3/t24-,25+,26+,27+,28+,29+,30-,31+,32-,33-,34+,35+,36+,37+,38-,39-,40-,41-,42+,43+,44+,45+,46+,47-,48-,49-,51-,52+,53+,54-/m0/s1", //InChI
    czyc: "西洋参", //中药材
    cldm: "Panax quinquefolius L.", //拉丁名
    czwzs: "五加科人参属", //植物种属
    czwtp: "/image/Ginsenoside Rczw.png", //植物图片
    czcy: "", //中成药
    cff: "", //复方
    cxycf: "人参皂苷 Rb1", //效应成分
    cylzb: "中性粒细胞增长率", //药理指标
    cylzy: "增强免疫", //药理作用
    cgxd: "中性粒细胞增长率（15.4%）", //贡献度
  },
];

export default TreeList;
