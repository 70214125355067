<template>
  <div id="app">
    <header class="header-container">
      <div>
        <img class="logo-container" src="/logo/logo.png" />
      </div>
      <div class="logo-title">
        中药药效物质数字化解析平台
      </div>
      <div class="select-container">
        <el-select :clearable="true" v-model="selectValue" placeholder="请选择查询内容">
          <el-option v-for="(item, index) in selectOptions" :key="index" :label="item.label" :value="item.level">
          </el-option>
        </el-select>
      </div>
      <div class="search-input-container">
        <el-input style="width: 80%;" placeholder="请输入查询内容" v-model="searchValue">
        </el-input>
        <span class="search-button" @click="HandleSearchEvent">
          <i class=" el-icon-search"></i>
        </span>
      </div>
    </header>
    <el-tabs class="demo-tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页" name="1">
        <IndexPage v-if="tabsArr['1']" />
      </el-tab-pane>
      <el-tab-pane label="信息管理" name="2">
        <DetailsPage v-if="tabsArr['2']" :selectValueData="selectValue ? selectValue : null" :searchValueData="searchValue" />
      </el-tab-pane>
    </el-tabs>

    <div class="footer-fixd">
      <div class="titleList">
        <a class="titleItem" target="_blank" href="https://www.sdutcm.edu.cn/">山东中医药大学</a>
        <a class="titleItem" target="_blank" href="http://ywyjy.sdutcm.edu.cn/">药物研究院</a>
        <a class="titleItem" target="_blank" href="http://www.99cn.com.cn/">济南六角橙信息技术有限公司</a>
      </div>

      <div class="infoContent">
        <div class="infoLeft">
          <div class="infoTitle">版权所有：山东中医药大学药物研究院</div>
          <div class="infoTitle">
            地址：山东省济南市长清区大学科技园大学路4655号
          </div>
          <div class="infoTitle">邮编：250355 电话：0531-8962 8355</div>
        </div>
        <div class="infoRight">
          <!-- <img width="70" src="http://ywyjy.sdutcm.edu.cn/images/_20230328094328.jpg" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeList from '@/data/TypeList'
import DetailsPage from '@/components/DetailsPage.vue'
import IndexPage from '@/components/IndexPage.vue'

export default {
  name: 'App',
  components: {
    DetailsPage,
    IndexPage
  },
  data () {
    return {
      activeName: '1',
      selectValue: null,//下拉框选中的 level
      selectOptions: [],//下拉框中的数据
      searchValue: '',//查询输入框的值
      tabsArr: {
        '1': true,
        '2': false
      }
    };
  },
  mounted () {
    this.selectOptions = TypeList;
  },
  methods: {
    handleClick(tab) {
        for (var k in this.tabsArr) {
          if (tab.name != k) {
            this.tabsArr[k] = false
          } else {
            this.tabsArr[k] = true
          }
        }
      },
    // 立即点击
    ImmedClick (val) {
      for (var key in this.tabsArr) {
        if (val != key) {
          this.tabsArr[key] = false
        } else {
          this.tabsArr[key] = true
        }
      }
    },
    HandleSearchEvent () {
      this.activeName = '2'
      this.ImmedClick('2')
      // this.activeName = '2'
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 100%;
  top: 54px;
  height: calc(100vh - 54px - 100px);
  position: fixed;
  z-index: 999;
}

:deep() {
  .el-tabs__item {
    padding: 0 20px !important;
  }

  .el-tabs__item:hover {
    color: #09979b;
  }

  .el-tabs__item.is-active {
    color: #09979b;
  }

  .el-tabs__active-bar {
    background-color: #09979b;
  }
}

.header-container {
  top: 0;
  width: 100%;
  position: fixed;
  text-align: left;
  height: 54px;
  line-height: 54px;
  display: flex;
  align-content: space-between;
  background-color: #09979b !important;
}

.logo-container {
  margin-left: 10px;
  width: 35px;
  height: 34px;
  margin-top: 12px;
}

.logo-title {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

.content-container {
  flex: 1;
  padding: 40px;
}

.right-container {
  flex: 1;
  text-align: center;
}

.image-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 14px;
}

.image-container {
  margin-block: 20px;

  img {
    width: 300px;
  }
}

.logo-title {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

.content-container {
  overflow: auto;
  padding-bottom: 60px;
}

.select-container {
  margin-left: 30px;
}

.search-input-container {
  width: 500px;
  margin-left: 30px;
}

:deep(.el-input__inner) {
  height: 30px;
  line-height: 30px;
}

.search-button {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  background-color: #0d595f;
  height: 30px;
  line-height: 30px;
  width: 20%;
  text-align: center;
}

a {
  text-decoration: none;
  color: #333;
}

.footer-fixd {
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 999;
}

.ImageList {
  display: flex;
  justify-content: space-around;
  padding: 10px;

  .ImageItem {
    margin: 0 20px;
    width: 20%;
    border: 1px solid #f1f1f1;
    background: #f7f7f7;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.titleList {
  width: 100%;
  background: #f2f2f2;
  padding: 0px 40px;
  display: flex;
  flex-wrap: wrap;

  .titleItem {
    width: 25%;
    margin: 10px 0;
  }
}

.infoContent {
  background: #09979b;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0px 40px;

  .infoLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .infoTitle {
    margin: 5px 0;
  }
}
:deep(.el-main) {
  padding: 0 !important;
}
</style>
