<template>
  <!-- 首页 -->
  <div class="IndexPage">
    <el-main>
      <div style="display: flex;">
        <div class="right-container">
          <div class="image-container">
            <img style="width:320px" src="/chartimage/chart1.png" />
          </div>
          <p style="font-size:10px">黄芩药材各成分抑菌作用的正交偏最小二乘回归分析</p>
          
          <div class="image-container">
            <img style="width:320px" src="/chartimage/chart2.png" />
          </div>
          <p style="font-size:10px">金银花药材成分抗RSV病毒作用的LS-SVM分析</p>
        </div>
        <div class="content-container">
          <span style="line-height: 40px;">
            <p style="text-indent: 2em;font-size:20px">
              本平台以中药药效物质为核心，整合中药材、中成药和药理药效数据，构建“药品-成分-功效”数字化信息网络。
            </p>
            <p style="text-indent: 2em;font-size:20px">中药药效物质数据基于赵渤年教授领衔团队研发的彰显临床价值的中药谱-效相关质量评价新模式和中药药效物质基础挖掘关键技术，通过真实实验获得。</p>
            <p style="text-indent: 2em;font-size:20px">
              本平台基于中药药效物质基础研究所获得的有效成分，将助力构建有效成分组方配伍筛选及开发中药有效成分组方新药。本平台可供高校、科研院所、企业及相关从业人员使用。</p>
          </span>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',


  data () {
    return {

    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },


}
</script>
<style lang="scss" scoped>
.header-container {
  top: 0;
  width: 100%;
  position: fixed;
  text-align: left;
  height: 54px;
  line-height: 54px;
  display: flex;
  align-content: space-between;
  background-color: #09979b !important;
}

.logo-container {
  margin-left: 10px;
  width: 35px;
  height: 34px;
  margin-top: 12px;
}

.logo-title {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

.content-container {
  flex: 1;
  padding: 40px;
}

.right-container {
  flex: 1;
  // padding-left: 12rem;
  text-align: center;

}

.image-container {


  img {
    width: 300px;
  }
}

.logo-title {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

.content-container {
  overflow: auto;
  height: calc(100vh - 100px);
  padding-bottom: 60px;
}

.select-container {
  margin-left: 30px;
}

.search-input-container {
  width: 500px;
  margin-left: 30px;
}

:deep(.el-input__inner) {
  height: 30px;
  line-height: 30px;
}

.search-button {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  background-color: #0d595f;
  height: 30px;
  line-height: 30px;
  width: 20%;
  text-align: center;
}

// .IndexPage {
//   :deep(.el-tabs__item.is-active) {
//     color: #09979b;
//     background-color: #fff;
//   }
// }</style>
