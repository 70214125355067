<template>
  <!-- 信息管理 -->
  <div style="overflow: auto; width: 100%">
    <!-- header -->
    <header class="header-container">
      <div>
        <img class="logo-container" src="/logo/logo.png" />
      </div>
      <div class="logo-title">中药药效物质数字化解析平台</div>
      <div class="select-container">
        <el-select
          :clearable="true"
          v-model="selectValue"
          placeholder="请选择查询内容"
        >
          <el-option
            v-for="(item, index) in selectOptions"
            :key="index"
            :label="item.label"
            :value="item.level"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-input-container">
        <el-input
          style="width: 80%"
          placeholder="请输入查询内容"
          v-model="searchValue"
        >
        </el-input>
        <span class="search-button" @click="HandleSearchEvent">
          <i class="el-icon-search"></i>
        </span>
      </div>
    </header>
    <div
      v-if="isShow"
      class="image-container"
      style="display: flex; justify-content: center; padding-top: 0px"
    >
      <img style="width: 50rem" src="/chartimage/chart3.png" />
    </div>
    <el-main v-if="!isShow" class="content-container" style="">
      <div v-for="(item, ind) in dataList" :key="ind" style="padding-bottom:120px">
        <div
          v-if="searchValue == '绿原酸' && selectValue == '3'"
          class="type_title"
        >
          绿原酸
          <!-- {{ item.title }} -->
        </div>
        <el-collapse
          style=""
          v-for="(item, index) in item.dataCollapse"
          :key="index"
          v-model="activeNames"
          @change="handleChange"
        >
          <el-collapse-item :title="`基本信息 `" name="1">
            <el-card style="width: 1100px; margin: 40px auto">
              <div style="display: flex">
                <div style="flex: 1">
                  <img
                    :src="item.cjgt ? item.cjgt : '/image/none.jpeg'"
                    class="dataCollapse_image"
                  />
                </div>
                <div style="flex: 1">
                  <span class="dataCollapse_label"
                    >编号：<span class="dataCollapse_content">{{
                      item.cbh
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >名称（英文）：<span class="dataCollapse_content">{{
                      item.cmc
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >分子式：<span class="dataCollapse_content">{{
                      item.cfzs
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >分子量：<span class="dataCollapse_content">{{
                      item.cfzl
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >通用名/商品名（中文）：<span
                      class="dataCollapse_content"
                      >{{ item.ctym }}</span
                    ></span
                  >
                  <span class="dataCollapse_label"
                    >CAS号：<span class="dataCollapse_content">{{
                      item.ccas
                    }}</span></span
                  >
                </div>
              </div>
            </el-card>
          </el-collapse-item>
          <el-collapse-item title="结构信息" name="2">
            <el-card style="width: 1100px; margin: 40px auto">
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                SMILES
              </p>
              <span class="dataCollapse_label">{{ item.csmiles }}</span>
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                InChI
              </p>
              <span class="dataCollapse_label">{{ item.cinchi }}</span>
            </el-card>
          </el-collapse-item>
          <el-collapse-item title="来源" name="3">
            <el-card style="width: 1100px; margin: 40px auto">
              <div style="display: flex">
                <div style="flex: 1">
                  <img
                    :src="item.czwtp ? item.czwtp : '/image/none.jpeg'"
                    class="dataCollapse_image"
                  />
                </div>
                <div style="flex: 1">
                  <span class="dataCollapse_label"
                    >中药材：<span class="dataCollapse_content">{{
                      item.czyc
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >拉丁名：<span class="dataCollapse_content">{{
                      item.cldm
                    }}</span></span
                  >
                  <span class="dataCollapse_label"
                    >植物种属：<span class="dataCollapse_content">{{
                      item.czwzs
                    }}</span></span
                  >
                </div>
              </div>
            </el-card>
          </el-collapse-item>
          <el-collapse-item title="相关制剂" name="2">
            <el-card style="width: 1100px; margin: 40px auto">
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                中成药
              </p>
              <span class="dataCollapse_label">{{
                item.czcy ? item.czcy : "暂无数据"
              }}</span>
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                复方
              </p>
              <span class="dataCollapse_label">{{
                item.cff ? item.cff : "暂无数据"
              }}</span>
            </el-card>
          </el-collapse-item>
          <el-collapse-item title="药理药效" name="2">
            <el-card style="width: 1100px; margin: 40px auto">
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                效应成分
              </p>
              <span class="dataCollapse_label">{{ item.cxycf }}</span>
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                药理指标
              </p>
              <span class="dataCollapse_label">{{ item.cylzb }}</span>
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                药理作用
              </p>
              <span class="dataCollapse_label">{{ item.cylzy }}</span>
              <p
                style="
                  text-align: left;
                  font-weight: bold;
                  margin: 0 20px;
                  color: rgb(0, 0, 0, 0.6);
                "
              >
                贡献度
              </p>
              <span class="dataCollapse_label">{{ item.cgxd }}</span>
            </el-card>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-main>

    <div
      v-if="!isShow"
      style="
        position: fixed;
        right: 0;
        top: 50%;
        padding: 10px 15px;
        z-index: 999;
        background-color: #f1f1f1;
        transform: translateY(-50%);
      "
    >
      <p>基本信息</p>
      <p>结构信息</p>
      <p>来源</p>
      <p>相关制剂</p>
      <p>药理药效</p>
    </div>
  </div>
</template>
<script>
import TypeList from "@/data/TypeList";
import TreeList from "@/data/TreeList";

export default {
  name: "DetailsPage",
  props: {
    searchValueData: {
      type: String,
      default: "",
    },
    selectValueData: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectValue: null, //下拉框选中的 level
      selectOptions: [], //下拉框中的数据
      searchValue: "", //查询输入框的值
      dataCollapse: [1],
      activeNames: ["1", "2", "3"],
      type_title: false,
      dataList: [],
      isShow: true,
    };
  },
  watch: {
    dataList: {
      handler(newVal) {
        if (
          !newVal ||
          newVal?.length <= 0 ||
          newVal[0]?.dataCollapse.length <= 0
        ) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }
        // console.log(newVal[0]?.dataCollapse.length)
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.selectOptions = TypeList;
    this.selectValue = this.selectValueData;
    this.searchValue = this.searchValueData;
    this.HandleSearchEvent();
  },
  methods: {
    handleChange() {
      // console.log(val);
    },

    HandleSearchEvent() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let data = TreeList;
      let arr = [];
      if (this.selectValue == 1) {
        this.type_title = false;
        arr.push({
          dataCollapse: data.filter(
            (item) => item.firstTitle == this.searchValue
          ),
        });
      } else if (this.selectValue == 2) {
        // 通过二级标题筛选出来所有符合条件的对象
        let secondList = data.filter(
          (item) => item.secondTitle == this.searchValue
        );
        // 把筛选出来的数组的所有一级标题取出来
        let firstList = secondList.map((item) => item.firstTitle);
        // 把一级标题去重
        const uniqueArr = Array.from(new Set(firstList));
        // 长度大于1才分组
        if (uniqueArr.length > 1) {
          this.type_title = true;
        } else {
          this.type_title = false;
        }

        // 循环去重过的一级标题数组  例如 ['金银花','银黄颗粒']
        for (let i = 0; i < uniqueArr.length; i++) {
          arr.push({
            title: uniqueArr[i],
            dataCollapse: secondList.filter(
              (item) => item.firstTitle == uniqueArr[i]
            ),
          });
        }
      } else {
        // 通过label筛选出来所有符合条件的对象
        let thirdList = data.filter((item) => item.label == this.searchValue);
        // 把筛选出来的数组的所有一级标题取出来
        let firstList = thirdList.map((item) => item.firstTitle);
        // 把一级标题去重
        const uniqueArr = Array.from(new Set(firstList));
        // 长度大于1才分组
        if (uniqueArr.length > 1) {
          this.type_title = true;
        } else {
          this.type_title = false;
        }

        for (let i = 0; i < uniqueArr.length; i++) {
          arr.push({
            title: uniqueArr[i],
            dataCollapse: thirdList.filter(
              (item) => item.firstTitle == uniqueArr[i]
            ),
          });
        }
      }
      this.dataList = arr;

      setTimeout(() => {
        loading.close();
      }, 500);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.header-container {
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 999;
  text-align: left;
  height: 54px;
  line-height: 54px;
  display: flex;
  // justify-content: space-around;
  align-content: space-between;
  // padding: 10px;
  background-color: #09979b !important;
}

.logo-container {
  margin-left: 10px;
  width: 35px;
  height: 34px;
  margin-top: 12px;
}

.logo-title {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
}

:deep(.content-container) {
  overflow: auto;
  height: calc(100vh - 100px);
  padding-bottom: 160px !important;
  position: relative;
}

.select-container {
  margin-left: 30px;
}

.search-input-container {
  width: 500px;
  margin-left: 30px;
}

:deep(.el-input__inner) {
  height: 30px;
  line-height: 30px;
}

.search-button {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  background-color: #0d595f;
  height: 30px;
  line-height: 30px;
  width: 20%;
  text-align: center;
}

.dataCollapse_image {
  width: 415px;
  height: 415px;
}

.dataCollapse_label {
  text-align: left;
  display: block;
  border-bottom: 1px solid #cfcfcf;
  margin: 0 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.dataCollapse_content {
  font-weight: 400!important;
}

.type_title {
  color: #09979b;
  font-size: 26px;
  font-weight: 600;
}

:deep(.el-collapse-item__header) {
  margin-top: 40px;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #fff;
  color: #036468;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 22px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  padding: 30px;
}

a {
  text-decoration: none;
  color: #333;
}

.footer {
  width: 100%;
  bottom: 0px;
  left: 0;
  right: 0;
  position: fixed;
}

.ImageList {
  display: flex;
  justify-content: space-around;
  padding: 20px;

  .ImageItem {
    margin: 0 20px;
    width: 20%;
    border: 1px solid #f1f1f1;
    background: #f7f7f7;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.titleList {
  width: 100%;
  background: #f2f2f2;
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;

  .titleItem {
    width: 25%;
    margin: 10px 0;
  }
}

.infoContent {
  background: #106625;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 20px 40px;

  .infoLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .infoTitle {
    margin: 10px 0;
  }
}
</style>
