let TypeList = [
  {
    level: 3,
    label: "药效物质",
  },
  {
    level: 1,
    label: "中药材/中成药",
  },
  {
    level: 2,
    label: "药理作用",
  },

];

export default TypeList;
